.over-flow-slider {
  height: 700px;
  overflow-y: scroll;
  padding-top: 30px;
  padding-bottom: 30px;
  background: url("../../assets/images/countless-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
}
.over-flow-slider::-webkit-scrollbar {
  display: none;
}
.colab-heading {
  font-size: 40px;
  color: white;
  text-transform: uppercase;
  margin-left: 7.2%;
  font-family: "Gotham-Bold";
}
.colab-heading span {
  border-bottom: 3px solid #fcb514;
  letter-spacing: 1px;
  word-spacing: 2px;
}
.colab-cards,
.placement-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.colab-card {
  width: 190px;
  height: 150px;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 5px;
}
.colab-card div {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.colab-card div img {
  margin-top: 20px;
  width: 150px;
  height: 100px;
  -o-object-fit: contain;
  object-fit: contain;
}
.placement-card {
  width: 300px;
  height: 300px;
  background-color: white;
  margin-bottom: 20px;

  background-size: cover;
  background-position: center;
}
.placement-card:nth-child(1) {
  background-image: url("../../assets/images/scroll/Someofourothertopplacements/1.png");
}
.placement-card:nth-child(2) {
  background-image: url("../../assets/images/scroll/Someofourothertopplacements/2.png");
}
.placement-card:nth-child(3) {
  background-image: url("../../assets/images/scroll/Someofourothertopplacements/3.png");
}
.placement-card:nth-child(4) {
  background-image: url("../../assets/images/scroll/Someofourothertopplacements/4.png");
}
.abt-student {
  margin-top: 241px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
}
.student-package {
  background-color: #fcb514;
  padding: 10px 2px 5px 15px;
}
.student-package p {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 0.3;
}
.student-package span {
  font-size: 12px;
}
.placed-company {
  padding-top: 15px;
  padding-left: 10px;
  -o-object-fit: contain;
  object-fit: contain;
}

.placed-company img {
  width: 100px;
  height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
}
.card-item {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center;
  gap: 50px;
}
.card-img {
  width: 40%;
}
.card-img img {
  width: 400px;
  height: 600px;
  -o-object-fit: contain;
  object-fit: contain;
}
.slider-container {
  background: radial-gradient(
    183.33% 183.33% at 52.5% -53.57%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  ) !important;
  color: white;
  padding-bottom: 1%;
}
.job-slider {
  color: white;
  padding-bottom: 2%;
}
.card-content {
  width: 400px;
}
.card-content p:nth-child(1) {
  line-height: 43px;
  font-size: 40px;
  text-transform: none !important;
  font-family: Gotham-ThinItalic;
  color: rgba(255, 255, 255, 0.623);
}
.intern-students p:nth-child(1) {
  font-size: 35px;
  font-style: normal;
  color: white;
  font-family: "Gotham-Bold";
  text-transform: uppercase !important;
}
.intern-students p:nth-child(2) {
  font-size: 20px;
  font-family: "Gotham-Medium";
}
.js-content {
  width: 50%;
  letter-spacing: 2px;
  word-spacing: 1px;
  font-size: 18px;
  text-transform: none;
  font-family: "Gotham-Bold";
}
.js-content p:nth-child(1) {
  font-size: 30px;
}
.js-content div {
  padding-left: 10px;
  border-left: 2px solid #fcb514;
  line-height: 1;
}

.js-content div p:nth-child(1) {
  font-size: 30px !important;
  font-weight: bold;
  color: #fcb514;
}
.js-content div p:nth-child(2) {
  font-size: 30px;
  font-family: "Gotham-Medium";
}
.js-content div div {
  width: 205px;
  height: 75px;
  background-color: white;
  border: none;
  border-radius: 5px;
  padding-top: 10px;
}
.js-content div div img {
  margin-left: -10px;
  width: 205px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.package {
  margin-top: 50px;
  font-size: 80px !important;
  font-weight: bold;
}
.package span {
  font-size: 48px;
}
.student-details {
  line-height: 1;
  font-size: 20px;
  margin-top: 16%;
  letter-spacing: 1px;
  word-spacing: 2px;
  text-transform: none !important;
}
.student-details p:nth-child(1) {
  font-size: 40px;
  color: #fcb514;
  font-family: "Gotham-Bold";
}
.student-details p:nth-child(2) {
  font-family: "Gotham-Bold";
}
.student-details p:nth-child(3) {
  font-family: "Gotham-Medium";
}
.offered-intern-company {
  width: 180px;
  height: 80px;
  background-color: white;
  border-radius: 10px;
}
.offered-intern-company img {
  margin-left: 5px;
  margin-top: 10px;
  width: 170px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.opportunities {
  margin-left: 1%;
  margin-right: 1%;
}
.opportunities div {
  background-color: transparent !important;
}
.opport-content {
  text-align: left !important;
  font-family: "Gotham-Bold";
  font-weight: 400 !important;
  color: white !important;
}
.opport-content p:nth-child(1) {
  font-size: 50px;
  text-transform: uppercase;
  border-bottom: 3px solid #fcb514;
}
.opport-content p:nth-child(2) {
  font-size: 30px;
  text-transform: uppercase;
}
.opport-content p:nth-child(3) {
  font-size: 20px;
  text-transform: none !important;
  font-family: "Gotham-Medium" !important;
}
.companies-offers div {
  color: black !important;
  background-color: transparent !important;
  border-right: 3px solid white !important;
  line-height: 1;
}
.companies-offers div p:nth-child(1) {
  font-size: 40px;
  color: #fcb514;
  font-family: "Gotham-Black";
}
.companies-offers div p:nth-child(2) {
  margin-top: 30px;
  font-size: 24px;
  color: white;
  font-family: "Gotham-Book" !important;
}
.grp {
  border: none !important;
}
.grp img {
  width: 90%;
  -webkit-width: 100%;
  height: 90%;
  -webkit-height: 80%;
  -o-object-fit: contain;
  object-fit: contain;
}
.prominient-com {
  margin: auto;
  background-color: white;
  width: 80%;
  height: auto;
  border-radius: 10px;
  padding: 20px 30px 20px 30px;
  overflow-x: auto;
  white-space: nowrap;
}
.rec-heading {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 2px;
}
.student {
  width: 400px;
  height: 300px !important;
}
.slide-card-main {
  margin-left: 55px;
}
.x-axis-slider {
  overflow-x: scroll;
  height: 150px;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
}
.x-axis-slider img {
  margin-top: 30px;
  -o-object-fit: contain;
  object-fit: contain;
}
.x-axis-conatiner {
  padding: 30px 30px 30px 30px;
  text-align: center;
  margin: auto;
  width: 85%;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 20px;
}
.x-axis-conatiner p {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1px;
}
.company-logo-card {
  scroll-snap-align: start;
  height: auto;
  border-right: 3px solid #fcb514;
  padding-right: 30px;
  margin-right: 30px;
}
.company-logo-card img {
  width: 150px;
  height: 80px;
  -o-object-fit: contain;
  object-fit: contain;
  pointer-events: none;
}
#x-axis-scroll-heading {
  font-family: "Gotham-Bold";
  font-weight: 400;
}
.coma-vector {
  position: absolute;
  width: 6%;
  margin-top: -10px;
  margin-left: -120px;
}
@media screen and (max-width: 320px) {
  .opport-content p:nth-child(1) {
    font-size: 25px;
  }
  .card-item {
    gap: 10px;
  }
  .card-content {
    width: 350px;
  }
  .x-axis-conatiner {
    padding: 30px 50px 30px 50px;
  }

  .js-content,
  .card-img {
    width: 100%;
  }
  .offered-intern-company {
    margin-left: 30px;
  }
  .coma-vector {
    display: none;
  }
  .card-content {
    text-align: center;
  }
  .student-details {
    text-align: center;
  }
}
@media screen and (min-width: 321px) and (max-width: 480px) {
  .opport-content p:nth-child(1) {
    font-size: 25px;
  }
  .company-logo-card {
    scroll-snap-align: start;
    height: auto;
    border: none !important;
    padding-right: 50px;
    margin-right: 30px;
    padding-left: 40px;
  }
  .company-logo-card img {
    width: 200px !important;
    height: 80px;
    -o-object-fit: contain;
    object-fit: contain;
    pointer-events: none;
  }

  .card-item {
    gap: 10px;
  }
  .card-content {
    width: 350px;
  }
  .js-content,
  .card-img {
    width: 100%;
  }
  .offered-intern-company {
    margin-left: 35px;
  }
  .coma-vector {
    display: none;
  }
  .card-content {
    text-align: center;
  }
  .student-details {
    text-align: center;
  }
  .slide-card-main {
    margin-left: 10px !important;
  }
  .student {
    width: 350px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .js-content,
  .card-img {
    width: 100%;
  }
  .card-content {
    text-align: center;
  }
  .student-details {
    text-align: center;
  }
  .student {
    width: 380px;
  }
  .slide-card-main {
    margin-left: 10px !important;
  }
}
