.box-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}
.box-card {
  width: 500px;
  height: 500px;
}
.design-container {
  padding-left: 15%;
  padding-right: 15%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.imgs {
  background-image: url("../../assets/images/section7/Pictue-101\ 503\ x\ 423.jpg");
  background-size: cover;
  background-position: center;
}
.imgs-1 {
  background-image: url("../../assets/images/section7/Pictue-103\ 503\ x\ 423.jpg");
  background-size: cover;
  background-position: center;
}
.imgs-2 {
  background-image: url("../../assets/images/section7/Pictue-87\ 503\ x\ 423.jpg");
  background-size: cover;
  background-position: center;
}

.text-box {
  text-align: center;
  background-color: #faf4f5;
  padding: 15px 30px 0px 30px;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.text-box:hover {
  background: -o-radial-gradient(
    52.5% -53.57%,
    183.33% 183.33%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
  background: radial-gradient(
    183.33% 183.33% at 52.5% -53.57%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
}
.text-box:hover p {
  color: white;
}
.text-box-heading {
  color: rgba(128, 0, 0, 0.872);
  font-size: 40px;
  font-family: "Gotham-Bold";
}
.text-box-data {
  font-family: "Gotham-Medium";
  font-size: 18px;
  color: rgb(110, 110, 110);
}
/* -------------------------------------------------------------- */
.sport-container {
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 50px;
}
.sport-cards {
  width: 30%;
  height: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.sport-heading-container {
  margin-top: 3%;
  margin-left: 10%;
  margin-right: 10%;
}
.sport-heading-container p:nth-child(2) {
  font-size: 20px;
  font-family: "Gotham-Medium";
}

.sport-heading-container p:nth-child(1) {
  font-family: "Gotham-Bold";
  color: rgba(128, 0, 0, 0.906);
  font-size: 35px;
  text-transform: uppercase;
}
.sport-heading-container p:nth-child(1) span {
  border-bottom: 3px solid #fcb514;
}
.sport-img {
  width: 250px;
  height: 100%;
  background-image: url("../../assets/images/Lastsectionsports/Pictue-228.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.big-img-1 {
  width: 250px;
  height: 100%;
  background-image: url("../../assets/images/Lastsectionsports/Pictue-226.jpg");
  background-position: center;
}
.big-img-3 {
  width: 250px;
  height: 100%;
  background-image: url("../../assets/images/Lastsectionsports/Pictue-224.jpg");
  background-position: center;
}
.small-imgs {
  display: block;
}
.small-imgs div {
  width: 100px;
  height: 100px;
  background-color: maroon;
  margin-bottom: 10px;
  margin-left: 10px;
}
.img-1,
.img-2,
.img-3 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.spimg-1 {
  background-image: url(../../assets/images/sports/Swimming.JPG);
}
.spimg-2 {
  background-image: url(../../assets/images/sports/Volleyball.JPG);
}
.spimg-3 {
  background-image: url(../../assets/images/sports/TableTennis.JPG);
}
.spimg-4 {
  background-image: url(../../assets/images/sports/Squash.JPG);
}
.spimg-5 {
  background-image: url(../../assets/images/sports/Cricket.JPG);
}
.spimg-6 {
  background-image: url(../../assets/images/sports/Overview.JPG);
}
.spimg-7 {
  background-image: url(../../assets/images/sports/Badminton.JPG);
}
.spimg-8 {
  background-image: url(../../assets/images/sports/Banner.JPG);
}
.spimg-9 {
  background-image: url(../../assets/images/sports/SportsCalendar.JPG);
}

@media screen and (max-width: 320px) {
  /* Styles for smaller smartphones */
  .sport-cards {
    width: 80%;
    height: 400px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .design-container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media screen and (min-width: 321px) and (max-width: 480px) {
  /* Styles for larger smartphones */
  .sport-cards {
    width: 80%;
    height: 400px;
  }
  .design-container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
  /* Styles for tablets */
  .sport-cards {
    width: 80%;
    height: 400px;
  }
  .design-container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  /* Styles for small desktops and tablets in landscape */
  .sport-cards {
    width: 80%;
    height: 400px;
  }
  .design-container {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
}
.imgs,
.imgs-1,
.imgs-2,
.sport-img,
.big-img-1,
.big-img-3 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
