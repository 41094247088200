.footer {
  background: -o-radial-gradient(
    52.5% -53.57%,
    183.33% 183.33%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
  background: radial-gradient(
    183.33% 183.33% at 52.5% -53.57%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );

  padding-top: 80px;
  padding-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-family: "Open Sans", sans-serif;
  position: relative;
  z-index: 10;
}
.footer-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background-color: rgba(0, 0, 0, 0.893);
  color: rgba(255, 255, 255, 0.655);
  padding-top: 10px;
}
.f-white-logo img {
  margin-top: -20px;
  width: 300px;
  height: 150px;
  -o-object-fit: contain;
  object-fit: contain;
}
.f-contact-us {
  color: white;
  font-size: 15px;
}
.c-heading,
.info-heading span {
  font-size: 25px;
  border-bottom: 3px solid orange;
  padding-right: 20px;
}
.further-info {
  color: white;
  font-size: 15px;
}
.further-info p {
  font-family: "Gotham-Light";
}
.social-logos img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 30px;
  height: 30px;
}
.social-logos div {
  display: inline;
  padding: 10px;
  -o-object-fit: contain;
  object-fit: contain;
}
.other-logos {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.f-headingss {
  font-family: "Gotham-Bold";
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .footer {
    padding-top: 30px;
    text-align: center;
  }
  .footer-2 {
    text-align: center;
  }
  .f-white-logo {
    margin-bottom: 20px;
  }
  .f-contact-us p {
    text-align: center;
  }
  .further-info {
    margin-top: 20px;
    text-align: center;
  }
}
