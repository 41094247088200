@font-face {
  font-family: "Gotham-Black";
  src: url("./assets/Gotham-font-family/Gotham/gotham-black-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-black-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-black-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-black-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-black-webfont.svg#gothamblack")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Gotham-BlackItalic";
  src: url("./assets/Gotham-font-family/Gotham/gotham-blackitalic-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-blackitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-blackitalic-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-blackitalic-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-blackitalic-webfont.svg#gothamblack_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("./assets/Gotham-font-family/Gotham/gotham-bold-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-bold-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-bold-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-bold-webfont.svg#gothambold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-BoldItalic";
  src: url("./assets/Gotham-font-family/Gotham/gotham-bolditalic-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-bolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-bolditalic-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-bolditalic-webfont.svg#gothambold_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Book";
  src: url("./assets/Gotham-font-family/Gotham/gotham-book-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-book-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-book-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-book-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-book-webfont.svg#gothambook")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-BookItalic";
  src: url("./assets/Gotham-font-family/Gotham/gotham-bookitalic-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-bookitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-bookitalic-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-bookitalic-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-bookitalic-webfont.svg#gothambook_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Light";
  src: url("./assets/Gotham-font-family/Gotham/gotham-light-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-light-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-light-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-light-webfont.svg#gothamlight")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-LightItalic";
  src: url("./assets/Gotham-font-family/Gotham/gotham-lightitalic-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-lightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-lightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-lightitalic-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-lightitalic-webfont.svg#gothamlight_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("./assets/Gotham-font-family/Gotham/gotham-medium-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-medium-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-medium-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-medium-webfont.svg#gothammedium")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-Thin";
  src: url("./assets/Gotham-font-family/Gotham/gotham-thin-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-thin-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-thin-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-thin-webfont.svg#gothamthin")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-ThinItalic";
  src: url("./assets/Gotham-font-family/Gotham/gotham-thinitalic-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-thinitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-thinitalic-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-thinitalic-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-thinitalic-webfont.svg#gothamthin_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-XLight";
  src: url("./assets/Gotham-font-family/Gotham/gotham-xlight-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-xlight-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-xlight-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-xlight-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-xlight-webfont.svg#gothamextralight")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham-XLightItalic";
  src: url("./assets/Gotham-font-family/Gotham/gotham-xlightitalic-webfont.eot");
  src: url("./assets/Gotham-font-family/Gotham/gotham-xlightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/Gotham-font-family/Gotham/gotham-xlightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/Gotham-font-family/Gotham/gotham-xlightitalic-webfont.woff")
      format("woff"),
    url("./assets/Gotham-font-family/Gotham/gotham-xlightitalic-webfont.svg#gothamextralight_italic")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
