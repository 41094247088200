.container-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background: -o-linear-gradient(
      left,
      rgba(128, 0, 0, 0.749) 0%,
      rgba(20, 65, 102, 0) 100%
    ),
    url("../../assets/images/Current-Page-website-images-2.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(128, 0, 0, 0.749)),
      to(rgba(20, 65, 102, 0))
    ),
    url("../../assets/images/Current-Page-website-images-2.jpg");
  background: linear-gradient(
      90deg,
      rgba(128, 0, 0, 0.749) 0%,
      rgba(20, 65, 102, 0) 100%
    ),
    url("../../assets/images/Current-Page-website-images-2.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  background-repeat: no-repeat;
  padding-top: 80px;
  padding-bottom: 11%;
  position: relative;
  z-index: 100;
}
.leaf-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 50px;
  font-family: "Gotham-Bold";
  -webkit-font-family: "Gotham-Bold";
  text-align: center;
}
.rank-content {
  margin-top: -10px;
}
.leaf-card {
  width: 340px;
  height: 280px;
  margin-top: -20px;
  background-image: url("../../assets/images/black3.png");
  background-position: center;
  -webkit-background-position: center;
  background-size: cover;
  -webkit-background-size: cover;
}
.leaf-card div {
  margin-top: 60px;
}
#c1-text p {
  font-size: 50px;
  -webkit-font-size: 45px;
  line-height: 1;
  letter-spacing: 1px;
  color: white;
  -webkit-color: white;
  font-family: "Gotham-BoldItalic";
  -webkit-font-family: "Gotham-BoldItalic";
}
#c1-text span p {
  color: #ffc104 !important;
  font-size: 40px;
  font-style: italic;
}
.sliders {
  margin-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  gap: 5px;
}
.slider-card-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.slider-card-1 div:nth-child(1) {
  width: 100px;
  height: 100px;
  object-fit: contain;
  -webkit-object-fit: contain;
  -o-object-fit: contain;
}
.slider-card-1 div:nth-child(1) img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  -webkit-object-fit: contain;
  -o-object-fit: contain;
}
.slider-card-1 div {
  margin: 10px;
}

.slider-card-1 div img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -o-object-fit: contain;
  -webkit-object-fit: contain;
  object-fit: contain;
  background-color: white;
}
.slider-card-2 {
  margin-top: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.bar-council p {
  font-family: "Gotham-Bold";
  line-height: 0.6;
  color: white;
  font-weight: 600;
  font-size: 20px;
}
.bar-council span p {
  color: #ffc104;
  font-size: 30px;
}
.slider-heading {
  font-size: 20px;
  color: white;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Gotham-Bold";
}
.student-detail {
  color: white;
  font-weight: 400;
  font-size: 20px;
  text-transform: none;
}
.student-detail p:nth-child(1) {
  font-size: 18px;
  font-family: "Gotham-Bold";
}
.student-detail div {
  margin-left: -2px;
  width: 100px;
  height: 32px;
  -webkit-background-color: white;
  background-color: white;
  border-radius: 5px;
}

.student-detail div img {
  background-color: transparent !important;
  margin-left: 3px;
  margin-top: -5px;
  -o-object-fit: contain;
  object-fit: contain;
  -webkit-object-fit: contain;
  width: 90px;
  height: 28px;
  border-radius: 0 !important;
}
.scholarship-section {
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 3%;
  margin-bottom: 3%;
}
.grab-chances {
  font-family: "Gotham-Bold";
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  padding: 2% 5% 2% 5%;
  color: white;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  line-height: 0.8;
  border-radius: 20px;
  background: url("../../assets/images/Banner.png");
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  -webkit-background-position: center;
  background-repeat: no-repeat;
  letter-spacing: 1px;
}
.grab-chances-1 {
  width: 100%;
  height: 300px;
  padding: 30px 0px 30px 0px;
  background-image: url("../../assets/images/scholarship.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
.gc-2 p:nth-child(1) {
  font-size: 104px;
}
.gc-2 {
  margin-top: 50px;
}
.gc-1 p:nth-child(3) {
  font-size: 70px;
}
.change-col {
  color: #fbb414;
}
.gc-1 {
  padding-right: 10%;
  border-right: 3px solid white;
}
.mu-sat-para {
  font-family: "Gotham-Medium";
  font-size: 16px;
  letter-spacing: 1px;
  margin-top: 20px;
}
.phases {
  margin-top: 3%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
}
.phases p {
  font-family: "Gotham-Bold";
}
.phases button {
  font-family: "Gotham-Bold";
}
.phases-2 {
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.card {
  padding: 20px 50px 20px 50px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-family: "Gotham-Bold";
}
.card button {
  font-family: "Gotham-Bold";
  margin: auto;
  background-color: #fcb614de;
  -webkit-background-color: #fcb614de;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  color: white;
  -webkit-color: white;
  border-radius: 5px;
  font-size: 20px;
  -webkit-font-size: 18px;
  letter-spacing: 1px;
  padding: 10px 20px 10px 20px;
}
.card button a {
  color: white;
  text-decoration: none;
}
.card p:nth-child(2) {
  font-size: 24px;
  letter-spacing: 1px;
}
.phase {
  border: 3px solid maroon;
  color: maroon;

  font-size: 40px;
  border-radius: 5px;
  font-weight: bolder;
}
.bv-heading {
  font-size: 40px;
  font-family: "Gotham-Bold";
  color: rgba(128, 0, 0, 0.82);
}

.bv-heading span {
  border-bottom: 3px solid #fcb514;
  padding-bottom: 3px;
}
.bv-para {
  font-family: "Gotham-Medium";
  font-size: 20px;
  letter-spacing: 1px;
}
.business-venture {
  background-image: url("../../assets/images/business-venture-bg.png");
  background-size: cover;
  background-position: center;
  -webkit-background-size: cover;
  -webkit-background-position: center;
  padding-left: 6%;
  padding-right: 6%;
  padding-bottom: 2%;
}

.images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 50px;
}
.box-1 {
  display: block;
}
.box-1-1 {
  width: auto;
  margin-bottom: 5px;
  border: 3px solid maroon;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 37px;
  font-family: "Gotham-Bold";
  color: white;
  padding: 20px;
}
.box-1-1 div {
  padding-left: 20px;
  padding-right: 20px;
  border-right: 3px solid white;
}
.colorbox {
  background: -o-radial-gradient(
    52.5% -53.57%,
    183.33% 183.33%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
  background: radial-gradient(
    183.33% 183.33% at 52.5% -53.57%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
}
.num-span {
  font-size: 62px;
  font-family: "Gotham-Bold";
  font-weight: 400;
}
.box-1-2 {
  color: black;
}
.box-1-2 div {
  border-right: 3px solid maroon;
}
.bv-logo {
  margin-top: 10px;
  width: 50px;
  height: 50px;
}
.box-2 {
  background-image: url("../../assets/images/Group1.png");
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -webkit-background-repeat: no-repeat;
  padding: 0% 10% 0% 10%;
  border-radius: 10px !important;
}

.box-2 div {
  text-align: center;
}

.box-2 p {
  font-family: "Open Sans", sans-serif;
  color: white;
  font-weight: 600;
  font-size: 25px;
}

.box-2 p:nth-child(1) {
  font-family: "Gotham-Bold";
  font-weight: 400;
  color: #fbb414;
  font-size: 94px;
}
.box-2 p:nth-child(1) span {
  font-size: 52px;
}

.box-2 p:nth-child(2) {
  font-family: "Gotham-Bold";
  font-size: 40px;
  font-weight: 400;
}

.box-2 p:nth-child(3) {
  font-size: 30px;
  font-family: "Gotham-Light";
}
.tnc {
  font-size: 15px !important;
  font-family: "Gotham-Medium" !important;
  color: rgba(255, 255, 255, 0.772);
  font-weight: lighter !important;
}
.launching-banner {
  margin-bottom: 2%;
  font-family: "Gotham-Bold";
  background-color: #fbb414;
  -webkit-background-color: #fbb414;
  text-align: center;
  letter-spacing: 2px;
  font-size: 50px;
  color: #7a0d1cd9;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.launching-banner p:nth-child(1) span {
  border-bottom: 3px solid;
}

.launching-banner p:nth-child(2) {
  margin-top: -30px;
  border: none;
}
.launching-banner p:nth-child(2) span {
  font-size: 40px;
}
.launching-banner a {
  text-decoration: none;
  color: #7a0d1cd9;
}
.launching-banner:hover {
  background: -o-radial-gradient(
    52.5% -53.57%,
    183.33% 183.33%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
  background: radial-gradient(
    183.33% 183.33% at 52.5% -53.57%,
    #4a0c14 0%,
    #901626 50%,
    #4a0c14 97.92%
  );
  color: #fbb414 !important;
}
.launching-banner:hover a {
  color: #fbb414 !important;
}
.container-main-2 {
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: rgba(0, 0, 0, 0.918);
}
.section-heading {
  font-size: 40px;
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  word-spacing: 2px;
}
.section-heading p:nth-child(1) {
  font-family: "Gotham-Bold";
  margin-bottom: -3px;
  color: #fbb414;
}
.section-heading p:nth-child(2) {
  font-size: 30px;
  font-family: "Gotham-Medium";
}
.row {
  padding: 10px 0 10px 0;
}

.row div {
  background-color: white;
  color: #7a0d1c;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 10px 2px 10px 2px;
  letter-spacing: 2px;
}

.row div:nth-child(2) {
  border-right: 3px solid #7a0d1c;
}

.row-1 div {
  color: black;
}

.row-1 div:nth-child(1) {
  background-color: #fbb414;
  color: black;
}

.row-1 div:nth-child(2) {
  background-color: #fbb414;
  border: none;
  border-right: 3px solid black;
}
.row-1 div:nth-child(3) {
  background-color: #fbb414;
  border-right: 3px solid black;
}
.BAT {
  border-right: 3px solid black;
}
.drop-btn {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  background-color: transparent;
  border: none;
  font-weight: bold;
}
.dropdown {
  margin-top: -10px;
}
.menu {
  margin-top: 25px;
  margin-left: -2px;
  border-radius: 0 !important;
  background-color: white !important;
  width: 100%;
  text-align: left !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  letter-spacing: 1px !important;
}
.row-2 div {
  background-color: #fbb414;
  color: black;
}
.row-2 div:nth-child(1) {
  background-color: #fbb414;
  color: black;
  border-right: 3px solid black;
}
.row-2 div:nth-child(2) {
  border: none;
  border-right: 3px solid black;
}
.rdcs-card-1 {
  padding: 20px;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
  font-family: "Open Sans", sans-serif;
  color: white;
  max-width: max-content;
  font-weight: 600;
}

.rdcs-card-1 div:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.rdcs-card-1 div:nth-child(2) {
  font-family: "Gotham-Medium";
  letter-spacing: 1px;
}

.rdcs-card-1 button {
  background-color: #fbb414;
  color: white;
  font-weight: 600;
  border: none;
  width: 180px;
  height: 50px;
}
.rdcs-card-1 button a {
  color: white;
  text-decoration: none;
  font-family: "Gotham-Bold";
  font-weight: 400;
}
.rdcs-1 {
  background-image: url("../../assets/images/course_bg_3.png");
}
.rdcs-2 {
  background-image: url("../../assets/images/course_bg_4.png");
}
.rdcs-3 {
  background-image: url("../../assets/images/course_bg_1.png");
}
.rdcs-4 {
  background-image: url("../../assets/images/course_bg_2.png");
}
.rdcs-container {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
}
.container-main-3 {
  margin-top: 50px;
}
.con-1 {
  margin-top: 30px;
}
.card-head {
  font-size: 25px;
  font-family: "Gotham-Bold";
  font-weight: 400;
}
.ft-content {
  font-family: "Gotham-Medium";
  font-weight: normal;
  font-size: 20px;
}
.container-heading {
  text-align: center;
  font-size: 30px;
  letter-spacing: 1px;
  word-spacing: 2px;
  margin-bottom: 50px;
}
.container-heading p:nth-child(1) {
  color: rgba(128, 0, 0, 0.886);
  font-size: 40px;
  font-family: "Gotham-Bold";
}
.alumni-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 100px;
  margin-bottom: 30px;
}
.alumni-card {
  width: 400px;
  height: 400px;
  border-radius: 15px;
  background-size: cover;
  background-position: top;
  position: relative;
}
.alumni-card-1 {
  background-image: url("../../assets/images/1.png");
}
.alumni-card-1:hover {
  background-image: url("../../assets//images/11.png");
}
.alumni-card-2 {
  background-image: url("../../assets/images/2.png");
}
.alumni-card-2:hover {
  background-image: url("../../assets//images/22.png");
}
.alumni-card-3 {
  background-image: url("../../assets/images/3.png");
}
.alumni-card-3:hover {
  background-image: url("../../assets//images/33.png");
}
.alumni-card-4 {
  background-image: url("../../assets/images/4.png");
}
.alumni-card-4:hover {
  background-image: url("../../assets//images/44.png");
}
.c4-head span {
  font-family: "Gotham-Bold";
  font-size: 40px;
  color: #7a0d1ce1;
  border-bottom: 3px solid #fbb414;
}
.c4-text {
  margin-top: 20px;
}

.c4-text p:nth-child(1) {
  font-family: "Gotham-Black";
  font-size: 30px;
}
.c4-text p:nth-child(2) {
  font-family: "Gotham-Medium";
  font-size: 20px;
}
.text-head {
  font-size: 40px !important;
}
.container-4 {
  margin: 3%;
}
.sub-container-4 {
  margin-top: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
}
.c4-section {
  width: 30%;
  height: 350px;
  text-align: center !important;
  color: rgba(255, 255, 255, 0.811);
  font-size: 18px;
  font-weight: 600;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.subsections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-evenly;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.img-text {
  line-height: 1;
  font-family: "Gotham-Bold";
  font-weight: 400;
  font-size: 15px;
}
.big-img-text {
  margin-top: 310px;
  text-transform: capitalize;
}
.small-img-text {
  position: absolute;
  margin-top: 100px;
  text-transform: capitalize;
}
.big-img-2 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMU/Moonstone_2023.jpg") !important;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMU/Moonstone_2023.jpg") !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMU/Moonstone_2023.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center;
  -o-object-fit: contain;
  object-fit: contain;
}
.small-img {
  display: block;
}
.small-img div {
  border-radius: 20px;
  margin-bottom: 20px;
}
.sml-img-1,
.sml-img-2 {
  width: 200px;
  height: 163px;
  background-color: #fbb414;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.sml-img-1 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/The\ Sahityik\ _Literature\ Fest\ 2023.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images//LifeatMU/The\ Sahityik\ _Literature\ Fest\ 2023.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/The\ Sahityik\ _Literature\ Fest\ 2023.jpg");
  background-size: cover;
}
.sml-img-2 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/The\ Club\ Activities\ _University\ Photography\ Club_.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images//LifeatMU/The\ Club\ Activities\ _University\ Photography\ Club_.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/The\ Club\ Activities\ _University\ Photography\ Club_.jpg");
  background-size: cover;
}
.sml-img-11 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/Sports\ Club.jpg") !important;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images//LifeatMU/Sports\ Club.jpg") !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/Sports\ Club.jpg") !important;
  background-position: center;
}
.sml-img-22 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/CEO\ Conclave\ June\ 10\,\ 2023.jpg") !important;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images//LifeatMU/CEO\ Conclave\ June\ 10\,\ 2023.jpg") !important;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images//LifeatMU/CEO\ Conclave\ June\ 10\,\ 2023.jpg") !important;
  background-position: center;
}
.sec-2 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMU/Moonstone2023.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMU/Moonstone2023.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMU/Moonstone2023.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
}
.big-img {
  width: 200px;
  height: 350px;

  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMU/E-summit.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMU/E-summit.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMU/E-summit.jpg");
  border-radius: 20px;
  background-position: center;
  background-size: cover;
}
.container-2 {
  margin-top: 50px;
}
.cards-leaf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  margin: 10px;
}
.card-leaf {
  border: none;
  text-align: center;
  background-image: url("../../assets/images/black3.png");
  background-position: center;
  background-size: cover;
  padding: 5%;
}

.card-leaf div {
  margin-top: -30px;
}
.sp-bgimg-2 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/Er.DeepakGadhia\,ChairmanSunriseCSPIndia.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMUspeakers/Er.DeepakGadhia\,ChairmanSunriseCSPIndia.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/Er.DeepakGadhia\,ChairmanSunriseCSPIndia.jpg");
  background-size: cover;
  background-position: center;
}
.sp-sml-img-1 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/TAPTIROYYADAV.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMUspeakers/TAPTIROYYADAV.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/TAPTIROYYADAV.jpg");
}
.sp-sml-img-2 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/GauravGhelani.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMUspeakers/GauravGhelani.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/GauravGhelani.jpg");
  background-position: center;
}
.sml-img-33 {
  background: url("../../assets/images/LifeatMUspeakers/NandanGarg.jpg");
  background-position: center;
}
.sml-img-44 {
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/Drjanakpalta.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMUspeakers/Drjanakpalta.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/Drjanakpalta.jpg");

  background-position: center;
}
.sp-bgimg-22 {
  background-position: center;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/RahulBhargava.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMUspeakers/RahulBhargava.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/RahulBhargava.jpg");
}
.sp-sec-2 {
  border-radius: 20px;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/DrSiddarthKRastogi.jpg");
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(36.91%, rgba(0, 0, 0, 0)),
      color-stop(92.74%, #000000cd)
    ),
    url("../../assets/images/LifeatMUspeakers/DrSiddarthKRastogi.jpg");
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.91%, #000000cd 92.74%),
    url("../../assets/images/LifeatMUspeakers/DrSiddarthKRastogi.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 20px;
}
/* .apply-btns {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 50;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
} */
/* .apply-btns button {
  background-color: #fec00f;
  -webkit-background-color: #fec00f;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
  border: none;
  padding: 5px 15px 5px 15px;
  display: block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 150px;
} */
.apply-btn {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 50;
  margin: auto;
  margin-right: 0px;
  padding: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  right: 0.9em;
}
.apply-btn button {
  border: none !important;
  height: 30px;
  width: 150px;
  max-height: 30px;
  max-width: 170px;
  background-color: #fec00f;
  color: white;
  font-weight: 600;
  font-size: 1.2em;
  border: none;
  padding: 0;
  display: block;
}
.c1-section-2 {
  width: 450px;
  border: 2px solid white;
  border-radius: 10px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 100px;
  background-color: revert-layer;
  border-radius: 8%;
}
#student-batch {
  color: #fbb414;
  font-size: 14px;
  font-family: "Gotham-Medium";
}
#student-stream {
  margin-top: -15px;
  font-size: 12px;
  font-family: "Gotham-Medium";
}
#student-package {
  margin-top: -15px;
  color: #fbb414;
  font-family: "Gotham-Bold";
}
.carousel-item {
  text-transform: uppercase !important;
}
.shift-btns {
  margin-top: 150px;
}
.shift-btns2 {
  margin-top: 240px;
}
.btns {
  font-family: "Gotham-Medium";
}
.futurestic-courses {
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: "Gotham-Bold";
  color: white;
  font-size: 40px;
  margin-right: 30%;
  border-bottom: 3px solid #fcb514;
}
.futurestic-courses p {
  line-height: 1.1;
  letter-spacing: 2px;
}
.activeated-card-1 {
  background-color: maroon !important;
  color: white !important;
}
.top-row div {
  font-size: 30px !important;
}
li {
  font-family: "Gotham-Medium";
  font-size: 20px !important;
}
.affiliate {
  margin-top: 60px;
  width: 100px;
  height: 100px;
}
.innovations {
  border: none !important;
  line-height: 0.6;
  margin-top: 30px;
  font-size: 23px;
  font-family: "Gotham-Medium";
}

@media screen and (max-width: 320px) {
  .courses-rows {
    border-bottom: 0.5px solid darkgray !important;
  }
  .grab-chances-1 {
    height: 75px;
  }
  .alumni-cards {
    padding-left: 3%;
    padding-right: 3%;
    gap: 30px;
  }

  .bar-council p {
    font-size: 20px;
  }
  .bar-council span p {
    color: #ffc104;
  }
  .alumni-card {
    width: 300px;
    height: 300px;
  }
  #c1-text {
    margin-left: 20px !important;
  }
  .slide-card-main {
    margin-left: 5px !important;
  }
  .gc-1 {
    border: none;
    font-family: "Gotham-Bold";
  }
  .box-1 {
    margin: auto;
  }
  .box-1-1 {
    padding: 10px;
  }
  .box-1-1 div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row div:nth-child(1) {
    border: none;
  }
  .row div:nth-child(2) {
    border: none;
  }
  .row div:nth-child(3) {
    border: none;
  }
  .rdcs-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cards-leaf {
    gap: 50px;
  }
  .c4-section {
    width: 100%;
  }
  .rdcs-card-1 {
    width: 100%;
  }

  .c4-section {
    width: 100%;
  }
  .big-img {
    width: 160px !important;
  }
  .sml-img-1,
  .sml-img-2 {
    width: 160px !important;
  }
}
@media screen and (min-width: 321px) and (max-width: 480px) {
  .courses-rows {
    border-bottom: 0.5px solid darkgray !important;
  }
  .futurestic-courses {
    margin-right: 5px !important;
  }
  .leaf-card div {
    margin-top: 60px;
    text-align: center;
    margin-left: 0px;
  }
  .grab-chances-1 {
    height: 75px;
  }
  .alumni-cards {
    padding-left: 3%;
    padding-right: 3%;
    gap: 30px;
  }

  .bar-council p {
    font-size: 20px;
  }
  .bar-council span p {
    color: #ffc104;
  }
  .alumni-card {
    width: 300px;
    height: 300px;
  }
  #c1-text {
    text-align: center;
  }
  .slide-card-main {
    margin-left: 5px !important;
  }
  .c1-section-2 {
    width: 330px !important;
    margin-top: 50px !important;
  }

  .gc-1 {
    border: none;
    margin-left: 30px;
    text-align: center;
  }
  .gc-2 {
    text-align: center;
  }
  .box-1 {
    margin: auto;
  }
  .box-1-1 {
    padding: 10px;
  }
  .box-1-1 div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row div:nth-child(1) {
    border: none;
  }
  .row div:nth-child(2) {
    border: none;
  }
  .row div:nth-child(3) {
    border: none;
  }
  .rdcs-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cards-leaf {
    gap: 50px;
  }
  .c4-section {
    width: 100%;
  }
  .rdcs-card-1 {
    width: 100%;
  }
  .c4-section {
    width: 100%;
  }
  .big-img {
    width: 160px !important;
  }
  .sml-img-1,
  .sml-img-2 {
    width: 160px !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .leaf-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
    font-family: "Gotham-Bold";
    -webkit-font-family: "Gotham-Bold";
    text-align: center;
  }
  .leaf-card {
    text-align: center;
    width: 330px;
    height: 270px;
    margin-top: -20px;
    background-image: url("../../assets/images/black3.png");
    background-position: center;
    -webkit-background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
  }
  .leaf-card div {
    margin-top: 60px;
    text-align: center;
    margin-left: -7%;
  }
  .grab-chances-1 {
    height: 200px;
  }

  .slider-heading {
    font-size: 20px !important;
  }
  .student {
    width: 280px !important;
  }
  .gc-1 {
    border: none;
  }

  .rdcs-container {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .c4-section {
    width: 55%;
  }
  .big-img {
    width: 180px;
  }
  .sml-img-1,
  .sml-img-2 {
    width: 180px;
  }
  .rdcs-card-1 {
    width: 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .slider-heading {
    font-size: 20px !important;
  }
  .student {
    width: 280px !important;
  }
  /* Styles for small desktops and tablets in landscape */
  .c4-section {
    width: 55%;
  }
  .big-img {
    width: 180px;
  }
  .sml-img-1,
  .sml-img-2 {
    width: 180px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .c4-section {
    width: 35%;
  }
  .big-img {
    width: 180px;
  }
  .sml-img-1,
  .sml-img-2 {
    width: 180px;
  }
}
@media screen and (min-width: 1950px) {
  .container-4,
  .designn-1-con,
  .design-2-con,
  .design-faq-con,
  .container-main-3,
  .container-2,
  .container-main-2-subcontainer,
  .business-venture-sub-container,
  .sub-over-flow-slider,
  .sub-slider-container,
  .scholarship-section {
    margin-left: 18%;
    margin-right: 18%;
  }
}
